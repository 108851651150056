<template>
    <Pie
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
    />
</template>

<script>
import { Pie } from "vue-chartjs/legacy";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
    name: "PieChart",
    components: {
        Pie,
    },
    props: {
        chartId: {
            type: String,
            default: "pie-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => {},
        },
        plugins: {
            type: Object,
            default: () => {},
        },
        labels: {
            type: Array,
            default: () => [],
        },

        backgroundColors: {
            type: Array,
            default: () => [],
        },

        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chartData: {
                labels: this.labels,
                position: "right",
                datasets: [
                    {
                        backgroundColor: this.backgroundColors,
                        data: this.data,
                    },
                ],
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: this.plugins,
            },
        };
    },
};
</script>
