<template>
    <div>
        <v-container
            id="dashboard"
            fluid
            class="fill-height flex-column flex-nowrap align-start"
        >
            <v-container fluid class="pt-5 px-5 mb-10">
                <v-row justify="space-between">
                    <v-col cols="12" md="7" class="d-flex">
                        <v-toolbar-title
                            class="headline font-weight-bold accent--text"
                            >Reports and Analytics</v-toolbar-title
                        >
                        <v-select
                            v-model="selectedDashboardPage"
                            background-color="transparent"
                            item-text="text"
                            item-value="value"
                            :menu-props="{ top: false, offsetY: true }"
                            class="ma-0 pa-0 ml-10 ministry-pages"
                            :items="dashboardItems"
                            @change="changeView"
                            color="primaryTextColor"
                            :style="`max-width: 200px; font-size: 20px; font-weight: 800;`"
                        ></v-select>
                    </v-col>
                </v-row>
            </v-container>
            <v-container
                fluid
                class="mt-n4 flex-grow-1 fill-height align-start justify-center px-6"
            >
                <v-row>
                    <v-col cols="12" md="12" class="d-flex">
                        <v-card flat width="100%" class="pa-4 card-shadow">
                            <v-row class="pa-4">
                                <v-col cols="12" md="8" sm="8" xs="8">
                                    <v-card
                                        rounded
                                        flat
                                        class="custom-info-card px-1 pb-3"
                                    >
                                        <div class="upper-card-info">
                                            <v-card-title
                                                class="display-1 font-weight-bold"
                                                >{{
                                                    totalRegisteredUsers
                                                }}</v-card-title
                                            >
                                            <v-chip
                                                class=""
                                                label
                                                color="#D1FCE7"
                                            >
                                                <v-icon>mdi-trending-up</v-icon>
                                                <span class="ml-1">12.13%</span>
                                            </v-chip>
                                        </div>
                                        <span class="px-4 card-info-title"
                                            >Total Registered Users</span
                                        >
                                    </v-card>
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    xs="4"
                                    style="
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: flex-start;
                                    "
                                    class="pr-8"
                                >
                                    <DateTimeFilter
                                        @changeMonthFilter="() => {}"
                                        @changeHourFilter="() => {}"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="py-2 text-center">
                                <v-col cols="12" md="12">
                                    <LineChart />
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" class="d-flex">
                        <v-card flat width="100%" class="pa-4 card-shadow">
                            <v-row>
                                <v-col cols="12" md="4" sm="4" xs="4">
                                    <v-card-title
                                        class="font-size-24"
                                        style="font-weight: bold"
                                        >Gender</v-card-title
                                    >
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="8"
                                    sm="8"
                                    xs="8"
                                    style="
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    "
                                    class="pr-8"
                                >
                                    <DateTimeFilter
                                        @changeMonthFilter="changeGenderMonth"
                                        @changeHourFilter="changeGenderHour"
                                    />
                                </v-col>
                            </v-row>

                            <v-row class="py-4 px-8 text-center">
                                <v-col cols="12" md="12">
                                    <div
                                        class="d-flex justify-center align-center"
                                        v-if="
                                            loadingDataSets.gender &&
                                            shouldLoadComponents
                                        "
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </div>

                                    <PieChart
                                        v-if="
                                            !loadingDataSets.gender &&
                                            checkDataValid(
                                                genderChartOptions.data
                                            )
                                        "
                                        :labels="genderChartOptions.labels"
                                        :background-colors="
                                            genderChartOptions.backgroundColors
                                        "
                                        :data="genderChartOptions.data"
                                        :plugins="chartOptions"
                                    />
                                    <span
                                        v-if="
                                            !loadingDataSets.gender &&
                                            !checkDataValid(
                                                genderChartOptions.data
                                            )
                                        "
                                        >No changes for this filter.</span
                                    >
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex">
                        <v-card flat width="100%" class="pa-4 card-shadow">
                            <v-row>
                                <v-col cols="12" md="4" sm="4" xs="4">
                                    <v-card-title
                                        class="font-size-24"
                                        style="font-weight: bold"
                                        >Age</v-card-title
                                    >
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="8"
                                    sm="8"
                                    xs="8"
                                    style="
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    "
                                    class="pr-8"
                                >
                                    <DateTimeFilter
                                        @changeMonthFilter="changeAgeMonth"
                                        @changeHourFilter="changeAgeHour"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="py-4 px-8 text-center">
                                <v-col cols="12" md="12">
                                    <div
                                        class="d-flex justify-center align-center"
                                        v-if="
                                            loadingDataSets.age &&
                                            shouldLoadComponents
                                        "
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </div>
                                    <BarChart
                                        v-if="
                                            !loadingDataSets.age &&
                                            checkDataValid(ageChartOptions.data)
                                        "
                                        :labels="ageChartOptions.labels"
                                        :background-colors="
                                            ageChartOptions.backgroundColors
                                        "
                                        :data="ageChartOptions.data"
                                    />
                                    <span
                                        v-if="
                                            !loadingDataSets.age &&
                                            !checkDataValid(
                                                ageChartOptions.data
                                            )
                                        "
                                        >No changes for this filter.</span
                                    >
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="6" class="d-flex">
                        <v-card flat width="100%" class="pa-4 card-shadow">
                            <v-row>
                                <v-col cols="12" md="4" sm="4" xs="4">
                                    <v-card-title
                                        class="font-size-24"
                                        style="font-weight: bold"
                                        >Membership</v-card-title
                                    >
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="8"
                                    sm="8"
                                    xs="8"
                                    style="
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    "
                                    class="pr-8"
                                >
                                    <DateTimeFilter
                                        @changeMonthFilter="
                                            changeMembershipMonth
                                        "
                                        @changeHourFilter="changeMembershipHour"
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="py-4 px-8 text-center">
                                <v-col cols="12" md="12">
                                    <div
                                        class="d-flex justify-center align-center"
                                        v-if="
                                            loadingDataSets.membership &&
                                            shouldLoadComponents
                                        "
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </div>
                                    <BarChart
                                        v-if="
                                            !loadingDataSets.membership &&
                                            checkDataValid(
                                                membershipChartOptions.data
                                            )
                                        "
                                        :labels="membershipChartOptions.labels"
                                        :background-colors="
                                            membershipChartOptions.backgroundColors
                                        "
                                        :data="membershipChartOptions.data"
                                        :datasets="[]"
                                    />
                                    <span
                                        v-if="
                                            !loadingDataSets.membership &&
                                            !checkDataValid(
                                                membershipChartOptions.data
                                            )
                                        "
                                        >No changes for this filter.</span
                                    >
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="6" class="d-flex">
                        <v-card flat width="100%" class="pa-4 card-shadow">
                            <v-row>
                                <v-col cols="12" md="4" sm="4" xs="4">
                                    <v-card-title
                                        class="font-size-24"
                                        style="font-weight: bold"
                                        >Devices</v-card-title
                                    >
                                </v-col>
                                <v-col
                                    cols="12"
                                    md="8"
                                    sm="8"
                                    xs="8"
                                    style="
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    "
                                    class="pr-8"
                                >
                                    <DateTimeFilter
                                        @changeMonthFilter="() => {}"
                                        @changeHourFilter="() => {}"
                                    />
                                </v-col>
                            </v-row>

                            <v-row class="py-4 px-8 text-center">
                                <v-col cols="12" md="12">
                                    <div
                                        class="d-flex justify-center align-center"
                                        v-if="
                                            loadingDataSets.gender &&
                                            shouldLoadComponents
                                        "
                                    >
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                        ></v-progress-circular>
                                    </div>

                                    <PieChart
                                        v-if="
                                            !loadingDataSets.gender &&
                                            checkDataValid(
                                                genderChartOptions.data
                                            )
                                        "
                                        :labels="genderChartOptions.labels"
                                        :background-colors="
                                            genderChartOptions.backgroundColors
                                        "
                                        :data="genderChartOptions.data"
                                        :plugins="chartOptions"
                                    />
                                    <span
                                        v-if="
                                            !loadingDataSets.gender &&
                                            !checkDataValid(
                                                genderChartOptions.data
                                            )
                                        "
                                        >No changes for this filter.</span
                                    >
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>

<script>
import PieChart from "@/components/Charts/Pie";
import BarChart from "@/components/Charts/Bar";
import LineChart from "@/components/Charts/Line";
import {
    GET_AGE_REPORT_DEMOGRAPHICS,
    GET_USER_REPORT_ANALYTICS,
    GET_GENDER_REPORT_DEMOGRAPHICS,
    GET_MEMBERSHIP_REPORT_DEMOGRAPHICS,
} from "@/store/modules/report";
import DateTimeFilter from "../components/DateTimeFilter.vue";
import moment from "moment";

export default {
    name: "dashboard",

    components: { PieChart, LineChart, BarChart, DateTimeFilter },

    data() {
        return {
            shouldLoadComponents: false,
            loadingDataSets: {
                gender: false,
                age: false,
                membership: false,
            },
            selectedDashboardPage: "/dashboard/users",
            selectedLineGraphItem: ["All"],
            lineGraphItems: [
                "All",
                "Test",
                "Test1",
                "Tes2t",
                "T3est",
                "T4est",
                "T5est",
                "T6est",
                "T7est",
            ],
            dashboardItems: [
                {
                    text: "Users",
                    value: "/dashboard/users",
                },
                {
                    text: "App Usage",
                    value: "/dashboard/app-usage",
                },
                {
                    text: "Session",
                    value: "/dashboard/session",
                },
            ],
            activatedAccounts: 0,
            inActiveAccounts: 0,
            totalRegisteredUsers: 0,

            chartOptions: {
                legend: {
                    position: "top",
                },
            },

            ageChartOptions: {
                labels: [],
                backgroundColors: [
                    "#52b453",
                    "#9ea65f",
                    "#82475d",
                    "#6908ef",
                    "#f86496",
                    "#99b1b0",
                    "#dc7f31",
                ],
                data: [],
            },

            genderChartOptions: {
                labels: ["Male", "Female"],
                backgroundColors: ["#3DFFDC", "#5A3FFF"],
                data: [],
            },

            membershipChartOptions: {
                labels: [],
                backgroundColors: [
                    "#52b453",
                    "#9ea65f",
                    "#82475d",
                    "#6908ef",
                    "#f86496",
                    "#99b1b0",
                    "#dc7f31",
                ],
                data: [],
            },
        };
    },

    watch: {
        selectedLineGraphItem(val) {
            if (
                val.length > 1 &&
                val.includes("All") &&
                val.indexOf("All") === 0
            ) {
                this.selectedLineGraphItem = val.filter(
                    (item) => item != "All"
                );
            }
            if (
                val.length > 1 &&
                this.selectedLineGraphItem[
                    this.selectedLineGraphItem.length - 1
                ] === "All"
            ) {
                this.selectedLineGraphItem = ["All"];
            }
        },
    },

    methods: {
        checkDataValid(data) {
            if (data.every((item) => item === 0)) return false;
            return true;
        },
        changeGenderMonth(e) {
            this.getGenderDemographics({
                year: moment(e).format("YYYY"),
                month: moment(e).format("MM"),
            });
        },
        changeGenderHour(e) {
            switch (e) {
                case "3":
                    return this.getGenderDemographics({ last_3_hours: true });
                case "12":
                    return this.getGenderDemographics({ last_12_hours: true });
                case "24":
                    return this.getGenderDemographics({ last_24_hours: true });
                case "7":
                    return this.getGenderDemographics({ last_7_days: true });
            }
        },
        changeAgeMonth(e) {
            this.getAgeDemographics({
                year: moment(e).format("YYYY"),
                month: moment(e).format("MM"),
            });
        },
        changeAgeHour(e) {
            switch (e) {
                case "3":
                    return this.getAgeDemographics({ last_3_hours: true });
                case "12":
                    return this.getAgeDemographics({ last_12_hours: true });
                case "24":
                    return this.getAgeDemographics({ last_24_hours: true });
                case "7":
                    return this.getAgeDemographics({ last_7_days: true });
            }
        },
        changeMembershipMonth(e) {
            this.getMembershipDemographics({
                year: moment(e).format("YYYY"),
                month: moment(e).format("MM"),
            });
        },
        changeMembershipHour(e) {
            switch (e) {
                case "3":
                    return this.getMembershipDemographics({
                        last_3_hours: true,
                    });
                case "12":
                    return this.getMembershipDemographics({
                        last_12_hours: true,
                    });
                case "24":
                    return this.getMembershipDemographics({
                        last_24_hours: true,
                    });
                case "7":
                    return this.getMembershipDemographics({
                        last_7_days: true,
                    });
            }
        },

        changeView(to) {
            this.$router.push(to);
        },

        async getUserAnalytics() {
            const result = await this.$store.dispatch(
                GET_USER_REPORT_ANALYTICS
            );
            const {
                activated_accounts,
                inactive_accounts,
                total_registered_users,
            } = result.data.data;
            this.activatedAccounts = activated_accounts || 0;
            this.inActiveAccounts = inactive_accounts || 0;
            this.totalRegisteredUsers = total_registered_users || 0;
        },

        async getAgeDemographics(payload) {
            this.loadingDataSets.age = true;
            const result = await this.$store.dispatch(
                GET_AGE_REPORT_DEMOGRAPHICS,
                payload
            );
            const demographics = result.data.data;

            this.ageChartOptions.labels = demographics.map((item) => {
                return item.age;
            });
            this.ageChartOptions.data = demographics.map((item) => {
                return item.count;
            });

            this.loadingDataSets.age = false;
        },

        async getGenderDemographics(payload) {
            this.loadingDataSets.gender = true;
            const result = await this.$store.dispatch(
                GET_GENDER_REPORT_DEMOGRAPHICS,
                payload
            );

            const demographics = result.data.data;
            this.genderChartOptions.data = demographics.map(
                (item) => item.percentage
            );
            this.loadingDataSets.gender = false;
        },

        async getMembershipDemographics(payload) {
            this.loadingDataSets.membership = true;
            const result = await this.$store.dispatch(
                GET_MEMBERSHIP_REPORT_DEMOGRAPHICS,
                payload
            );
            const demographics = result.data.data;
            this.membershipChartOptions.labels = demographics.map(
                (item) => item.membership
            );
            this.membershipChartOptions.data = demographics.map(
                (item) => item.count
            );
            this.loadingDataSets.membership = false;
        },
    },

    async created() {
        // await this.getUserAnalytics();
        await this.getAgeDemographics({
            year: moment().format("YYYY"),
            month: moment().format("MM"),
        });
        await this.getGenderDemographics({
            year: moment().format("YYYY"),
            month: moment().format("MM"),
        });
        await this.getMembershipDemographics({
            year: moment().format("YYYY"),
            month: moment().format("MM"),
        });
        this.shouldLoadComponents = true;
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#dashboard {
}

.custom-info-card {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
    border-radius: 8px !important;
    max-width: 250px;
}

.upper-card-info {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 0 15px 0 0;
}

.card-info-title {
    color: gray;
    font-size: 18px;
}

.v-select__selections {
    flex-wrap: nowrap !important;
}

.card-shadow {
    box-shadow: 1px 1px 6px rgba(43, 53, 116, 0.08) !important;
    min-height: 500px !important;
}
</style>
